<template>
  <div
    class="flex flex-wrap p-2"
    v-if="
      currentUser.type == 'Admin' ||
      currentUser.type == 'CEO' ||
      currentUser.type == 'Seller' ||
      currentUser.type == 'StockManager'
    "
  >
    <div class="w-full">
      <chart-revenus
        :chartData="dataStockIn"
        :chartData1="dataStockOut"
        :Labels="labelsStock"
        :label="'Quantity in stock Added'"
        :title="$t('stock_histories')"
        type="line"
        id="stock-histories"
        filter="true"
        :from="from"
        :to="to"
        :sellers="sellers"
        :userType="currentUser.type"
        :timeserchseller="timeserchstockhistoriesSeller"
        :timeserch="timeserchStock"
        :timeserchtockhistoriesproducts="timeserchtockhistoriesproducts"
        action="stockhistories"
        :labelstring="labelStringStock"
        :products="products"
        @ChangeTypeDateRevenu="ChangeTypeDateRevenu"
        @ChangeSeller="ChangeSellerStock"
        @ChangeProduct="ChangeProduct"
        @FindRecordRevenus="FindRecordStocks"
      />
    </div>
  </div>
</template>

<script>
import ChartRevenus from "@/components/Analytics/Cards/ChartRevenu.vue";
export default {
  name: "dashboard-page",
  components: {
    ChartRevenus,
  },
  props: {
    currentUser: { type: Object },
  },
  data() {
    return {
      dataStockIn: [],
      dataStockOut: [],
      labelsStock: [],
      sellers: [],
      idWharhouse: null,
      labelsExp: [],
      label: new Date().getFullYear(),
      timeserchstockhistoriesSeller: "All",
      timeserchStock: "today",
      timeserchtockhistoriesproducts: "...",
      labelStringStock: "Hours",
      products: [],
      from: null,
      to: null,
      labels: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      labelsMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"];
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      await this.getwharhouseId();
      await this.getSellers();
      if (this.currentUser.type == "Seller") {
        const res = await this.$server.search("products", {
          seller: this.currentUser._id,
          "details.warehouse": this.idWharhouse._id,
        });
        if (res.content.results) {
          this.products = res.content.results;
        } else this.products = [];
      }
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchStock,
        Product: this.timeserchtockhistoriesproducts,
        Warhouse: this.idWharhouse._id,
        action: "stockhistories",
      });
    },
  },
  async mounted() {
    await this.getwharhouseId();
    await this.getSellers();
    if (this.currentUser.type == "Seller") {
      const res = await this.$server.search("products", {
        seller: this.currentUser._id,
        "details.warehouse": this.idWharhouse._id,
      });
      if (res.content.results) {
        this.products = res.content.results;
      } else this.products = [];
    }
    await this.getRevenus_and_TopProducts_Stock({
      DateType: this.timeserchStock,
      action: "stockhistories",
      Warhouse: this.idWharhouse._id,
    });
    this.labelsStock = this.labels;
  },
  methods: {
    async getSellers() {
      const res = await this.$server.search("users", {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: 4000,
      });
      if (
        res.content.results &&
        (this.currentUser.type == "Admin" || this.currentUser.type == "CEO")
      ) {
        this.sellers = res.content.results;
        this.sellers.unshift("All");
      }
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async FindRecordStocks(from, to) {
      this.dataStockIn = this.dataStockOut = [];
      this.labelsStock = [];
      this.from = from;
      this.to = to;

      await this.getRevenus_and_TopProducts_Stock({
        DateType: "betweendate",
        Product: this.timeserchtockhistoriesproducts,
        Warhouse: this.idWharhouse._id,
        dates: { from: from, to: to },
        action: "stockhistories",
      });
    },
    async ChangeSellerStock(seller) {
      this.products = [];
      const res = await this.$server.search("products", { seller: seller._id });
      if (res.content.results) {
        this.products = res.content.results;
      } else this.products = [];
      this.timeserchstockhistoriesSeller = seller._id;
    },
    async ChangeProduct(product) {
      this.timeserchtockhistoriesproducts = product._id;
      this.dataStockIn = this.dataStockOut = [];
      this.labelsStock = [];
      await this.getRevenus_and_TopProducts_Stock({
        DateType: this.timeserchStock,
        Product: this.timeserchtockhistoriesproducts,
        Warhouse: this.idWharhouse._id,
        action: "stockhistories",
      });
    },
    async ChangeTypeDateRevenu(event) {
      this.timeserchStock = event.target.value;
      if (event.target.value != "betweendate") {
        if (event.target.name == "stockhistories") {
          this.labelsStock = [];
          this.dataStockIn = this.dataStockOut = [];
          if (event.target.value == "today") {
            this.labelStringStock = "Hours";
          } else if (event.target.value == "thisyear") {
            this.labelStringStock = "Months";
          } else {
            this.labelStringStock = "Days";
          }
        }

        await this.getRevenus_and_TopProducts_Stock({
          DateType: event.target.value,
          Product: this.timeserchtockhistoriesproducts,
          Warhouse: this.idWharhouse._id,
          action: event.target.name,
        });
      }
    },
    getTymeTypeStock() {
      var type = "";
      if (this.timeserchStock == "today") {
        type = "hour";
      } else if (this.timeserchStock == "thisyear") {
        type = "month";
      } else {
        type = "day";
      }
      return type;
    },
    getNumberOfMonths() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      let nbr_jours = new Date(year, month, 0).getDate();
      this.labels = [];
      return nbr_jours;
    },
    GetMonthName(monthNumber) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[monthNumber - 1];
    },
    async getRevenus_and_TopProducts_Stock(filters) {
      filters.SellerproductStock = this.timeserchtockhistoriesproducts;

      const res = await this.$server.getRevenus_and_TopProducts_Stock(
        "analytics",
        filters
      );

      if (res.content) {
        this.dataStockIn = res.content.dataInstockIn;
        this.dataStockOut = res.content.dataInstockOut;
        if (this.timeserchStock == "today") {
          this.labelsStock = this.labels;
        } else if (this.timeserchStock == "thisyear") {
          this.labelsStock = this.labelsMonths;
        } else if (
          this.timeserchStock == "thisweek" ||
          this.timeserchStock == "lastweek"
        ) {
          this.labelsStock = res.content.label;
        } else if (this.timeserchStock == "betweendate") {
          this.labelsStock = res.content.label;
          if (res.content.countMonths > 1) {
            for (let i in this.labelsStock) {
              this.labelsStock[i] = this.GetMonthName(this.labelsStock[i]);
            }
          }
        } else {
          let nbrjours = this.getNumberOfMonths();
          for (let i = 1; i <= nbrjours; i++) {
            this.labelsStock.push(i);
          }
        }
      } else {
        if (filters.action == "stockhistories") {
          this.dataStockIn = this.dataStockOut = [];
          this.labelsStock = [];
        }
      }
    },
  },
};
</script>
