var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'Seller' || _vm.currentUser.type == 'StockManager' ? _c('div', {
    staticClass: "flex flex-wrap p-2"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('chart-revenus', {
    attrs: {
      "chartData": _vm.dataStockIn,
      "chartData1": _vm.dataStockOut,
      "Labels": _vm.labelsStock,
      "label": 'Quantity in stock Added',
      "title": _vm.$t('stock_histories'),
      "type": "line",
      "id": "stock-histories",
      "filter": "true",
      "from": _vm.from,
      "to": _vm.to,
      "sellers": _vm.sellers,
      "userType": _vm.currentUser.type,
      "timeserchseller": _vm.timeserchstockhistoriesSeller,
      "timeserch": _vm.timeserchStock,
      "timeserchtockhistoriesproducts": _vm.timeserchtockhistoriesproducts,
      "action": "stockhistories",
      "labelstring": _vm.labelStringStock,
      "products": _vm.products
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "ChangeSeller": _vm.ChangeSellerStock,
      "ChangeProduct": _vm.ChangeProduct,
      "FindRecordRevenus": _vm.FindRecordStocks
    }
  })], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }